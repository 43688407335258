<template>
  <div class="auth donor" :class="{thanks: confirmedAmount}">
    <div class="donor-form-block welcome" v-if="!confirmedAmount">
      <v-card
        color="#D4EEEF"
        elevation="0"
        class="text-center mb-10"
      >
        <v-card-title class="justify-center pb-2">{{ currentUser.nameZh }}<br>{{ currentUser.nameEn }}</v-card-title>
        <v-card-subtitle class="mt-0">{{ $moment().format('D MMM YYYY') }}</v-card-subtitle>
      </v-card>
      <div class="auth-title">
        <strong>預計剩餘數量</strong>
        <span>Estimate Amount</span>
      </div>
      <v-row class="estimate-wrapper">
        <v-col
          v-for="amount of amounts"
          :key="amount.id"
          cols="12"
          sm="6"
        >
          <v-btn
            color="#FCF1EA"
            height="72"
            class="px-5"
            :ripple="false"
            :class="{'selected': selectedAmount.id === amount.id}"
            @click="select(amount)"
          >
            <img :src="amount.icon">
            <span :class="{'simple-text':!amount.icon}">{{ amount.text }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-btn
        color="primary"
        large
        width="276"
        max-width="100%"
        rounded
        class="mb-6 mt-7 ml-auto mr-auto"
        @click="submit"
      >
        確認 CONFIRM
      </v-btn>
    </div>
    <thanks v-else :selectedAmount="selectedAmount" @change="confirmedAmount = false"></thanks>
    <v-dialog
      v-model="dialog"
      max-width="475"
      content-class="delete-dialog"
    >
      <v-card>
        <v-card-title class="headline text-center secondary--text pb-4 flex-column">
          <strong class="mb-2">確認預計剩餘數量 <span class="primary--text">{{ selectedAmount.value }}</span> ？</strong>
          <span class="title">Confirm <span class="primary--text">{{ selectedAmount.value }}</span> buns<br>available for pick up?</span>
        </v-card-title>
        <v-card-actions class="justify-center pt-4">
          <v-btn
            class="modal-btn cancel"
            text
            width="147"
            max-width="49%"
            height="50"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-btn
            class="modal-btn ok"
            text
            width="147"
            max-width="49%"
            height="50"
            @click="confirm"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as runService from '@/services/run';
import Thanks from './thanks';

export default {
  name: 'estimation',
  data: () => ({
    confirmedAmount: false,
    selectedAmount: {},
    amounts: [
      {
        id: 1,
        icon: '/images/donor-bread-1.svg',
        text: '1 - 20',
        value: '1-20'
      },
      {
        id: 2,
        icon: '/images/donor-bread-21.svg',
        text: '21 - 30',
        value: '21-30'
      },
      {
        id: 3,
        icon: '/images/donor-bread-31.svg',
        text: '31+',
        value: '31+'
      },
      {
        id: 4,
        icon: false,
        text: '售罄 SOLD OUT',
        value: '0'
      }
    ],
    dialog: null
  }),
  methods: {
    select (amount) {
      this.selectedAmount = amount;
    },
    submit () {
      this.dialog = true;
    },
    confirm () {
      let id = this.$moment().format('YYYYMMDD') + String(this.currentUser.donorId).padStart(3, '0');
      let data = {
        expectedAmount: this.selectedAmount.value
      };
      runService.update(id, data)
        .then(() => {
          this.dialog = false;
          this.confirmedAmount = true;
        });
    }
  },
  components: { Thanks }
};
</script>
