<template>
  <div class="donor-form-block">
    <img class="logo" src="/images/logo.svg">
    <div class="auth-title">
      <strong>唔該晒</strong>
      <span>Thank you!</span>
    </div>
    <div class="text-expect">
      <span>{{ $moment().format('D MMM YYYY') }}</span>
      <strong>你預計 You are expecting:</strong>
    </div>
    <div class="selected-buns">
      <v-card
        color="#D4EEEF"
        elevation="0"
        class="text-center mb-4"
        width="228"
        height="72"
      >
        <v-card-title class="justify-space-between px-5 py-5">
          <img :src="selectedAmount.icon">
          <span>{{selectedAmount.text}}</span>
        </v-card-title>
      </v-card>
    </div>
    <v-btn
      color="primary"
      large
      width="305"
      max-width="100%"
      rounded
      class="mb-4"
      to="/donors/welcome"
    >
      返回主頁 HOME
    </v-btn>
    <v-btn
      color="primary"
      large
      width="305"
      max-width="100%"
      rounded
      @click="$emit('change')"
    >
      更改 CHANGE
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'Thanks',
  props: ['selectedAmount']
};
</script>
